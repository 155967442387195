import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, getUA, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH } from "../utils/apis";
import { setSession, setHeader } from "../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isVerifyEmail: false,
  isPopupOpen: false,
  isSwitchingAccount: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isVerifyEmail, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isVerifyEmail,
      isInitialized: true,
      isPopupOpen: false,
      isSwitchingAccount: false,
      user,
    };
  },
  SWITCH_ACCOUNT: (state, action) => {
    const { isSwitchingAccount } = action.payload;
    return {
      ...state,
      isSwitchingAccount,
    };
  },
  UPDATE: (state, action) => {
    const { user, isVerified } = action.payload;
    return {
      ...state,
      user,
      isVerifyEmail: isVerified,
    };
  },
  LOGIN: (state, action) => {
    const { user, isVerifyEmail } = action.payload;
    return {
      ...state,
      isVerifyEmail,
      isAuthenticated: true,
      isPopupOpen: false,
      user,
    };
  },
  LOGOUT: (state, action) => {
    const { isPopUp } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      isPopupOpen: isPopUp,
      isSwitchingAccount: false,
      user: null,
    };
  },
  VERIFY: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isVerifyEmail: true,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    setHeader();
    let isAuthenticated = false;
    let isVerifyEmail = false;
    let user = {};
    try {
      const response = await axios.post(API_PATH.profile, {
        get: "",
      });
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        isAuthenticated = Boolean(user.user.uid);
        // eslint-disable-next-line
        if (isAuthenticated && parseInt(user.user.isVerified, 10) === 1) {
          isVerifyEmail = true;
        }
      }

      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isAuthenticated = false;
        setSession(null);
      }
    } catch (err) {
      // null
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isVerifyEmail,
        user,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const logout = async () => {
    const isPopUp = false;
    logDevice(0);
    setSession(null);
    dispatch({ type: "LOGOUT", payload: { isPopUp } });
  };
  const login = async (username, password, method) => {
    const response = await axios.post(API_PATH.login, {
      username,
      password,
      method,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token);
      const isVerifyEmail = parseInt(data.user.isVerified, 10) === 1;
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail },
      });
    }
    if (status === "invalid_login") {
      throw new Error(
        "Your username or password is incorrect! Please confirm and try again."
      );
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
  };
  const signup = async (values) => {
    const response = await axios.post(API_PATH.signup, {
      ...values,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token);
      const isVerifyEmail = parseInt(data.user.isVerified, 10) === 1;
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail },
      });
    }
    if (status === "invalid_login") {
      throw new Error(
        "Your username or password is incorrect! Please confirm and try again."
      );
    }
    if (status === "email_taken") {
      throw new Error(
        "The email address specified is already in use! Please enter another and try again."
      );
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
  };
  const checkPhone = async (phone, country) => {
    const response = await axios.post(API_PATH.login, {
      checkPhone: phone,
      country,
    });
    const { status } = response.data;
    return status;
  };
  const logDevice = async (status) => {
    axios.post(API_PATH.login, {
      name: browserName,
      os: osName,
      deviceId: getUA,
      status,
      type: 0,
    });
  };

  const resetPassword = async (code, password) => {
    const response = await axios.post(API_PATH.forgot, {
      code,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your password reset code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your password reset code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your password reset code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while resetting your password! Please confirm and try again."
        );
      }
    }
  };
  const forgotPassword = async (email) => {
    const response = await axios.post(API_PATH.forgot, {
      email,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Your email address or username is incorrect! Please confirm and try again."
      );
    }
  };
  const saveToken = async (saveToken) => {
    await axios.post(API_PATH.notifications, { saveToken, device: getUA });
  };
  const verifyAccount = async (code, method, recipient, country) => {
    const response = await axios.post(API_PATH.login, {
      code,
      method,
      recipient,
      country,
      verify: "",
    });
    const { status, data } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your verification code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your verification code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your verification code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while verifying your account! Please confirm and try again."
        );
      }
    } else if (method === "email") {
      dispatch({
        type: "VERIFY",
        payload: { user: data },
      });
    } else {
      return data;
    }
  };
  const sendVerifyCode = async (recipient, country, method) => {
    const response = await axios.post(API_PATH.login, {
      send: "",
      recipient,
      country,
      method,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("Something went wrong! Please try again later.");
    }
  };
  const sendPasswordVerify = async () => {
    const { user } = state;
    const { usertype } = user.user;
    const uid = parseInt(usertype, 10) === 1 ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      generate_set_password: uid,
      type: usertype,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot send your set password code at the moment! Please try again later."
      );
    }
  };
  const topupWallet = async (values) => {
    const response = await axios.post(API_PATH.wallet, {
      topup: "",
      ...values,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot process your wallet topup at the moment! Please try again later or contact us if you were debited."
      );
    }
  };
  const transferWallet = async (amount, payoutType, payoutAccount) => {
    const response = await axios.post(API_PATH.wallet, {
      transfer: "",
      amount,
      payoutType,
      payoutAccount: JSON.stringify(payoutAccount),
    });
    const { status } = response.data;
    if (status === "insufficient_balance") {
      throw new Error(
        "You do not have sufficient balance to process this transfer! Please check your wallet balance and try again."
      );
    }
    if (status !== "success") {
      throw new Error(
        "We cannot process your wallet withdrawal at the moment! Please try again later or contact us if you were debited."
      );
    }
  };
  const switchAccount = async (businessType) => {
    dispatch({ type: "SWITCH_ACCOUNT", payload: { isSwitchingAccount: true } });
    await updateSettings({ businessType }, "business_type");
    await initialize();
  };
  const updateSettings = async (values, type) => {
    const response = await axios.post(API_PATH.settings, {
      update_profile: "",
      ...values,
      type,
    });
    const { status, data } = response.data;
    if (status === "success") {
      if (type !== "account") {
        dispatch({
          type: "UPDATE",
          payload: {
            user: data,
            isVerified: parseInt(data.user.isVerified, 10) === 1,
          },
        });
      }
      if (type === "delete_account" || type === "deactivate") {
        logout();
      }
      return data;
    }
    if (status === "error") {
      throw new Error(
        "Something went wrong while updating your profile. Please try again later."
      );
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "An error has occured! Please fill all the required fields correctly and try again."
      );
    }
    if (status === "invalid_password") {
      throw new Error(
        "The current password provided is incorrect. Please confirm and try again."
      );
    }
    if (status === "invalid_account") {
      throw new Error(
        "Please provide your payout account information to proceed."
      );
    }
  };

  const logoutDevice = async (values) => {
    await axios.post(API_PATH.login, {
      name: values.name,
      os: values.os,
      deviceId: values.deviceId,
      status: 0,
      type: values.type,
    });
  };
  const getDevices = async () => {
    const response = await axios.post(API_PATH.misc, { devices: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getPlans = async () => {
    const response = await axios.post(API_PATH.misc, { plans: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getBookingDefaults = async () => {
    const response = await axios.post(API_PATH.misc, { bookingDefaults: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return {};
  };
  const getBanks = async () => {
    const response = await axios.post(API_PATH.misc, { banks: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getRoles = async () => {
    const response = await axios.post(API_PATH.misc, { roles: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getListings = async (isUnique) => {
    const response = await axios.post(API_PATH.misc, {
      listings: "",
      isUnique: isUnique ? 1 : 0,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getStaffs = async () => {
    const response = await axios.post(API_PATH.misc, {
      staffs: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAccountName = async (bank, number) => {
    const response = await axios.post(API_PATH.misc, {
      verify_account: "",
      bank,
      number,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("The account entered is invalid! Please confirm.");
  };
  const updateCompliance = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      compliance: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data, isVerified: true } });
      return;
    }
    if (status === "verified") {
      throw new Error("Your ID is already verified!");
    }
    if (status === "invalid_data") {
      throw new Error(
        "The data you supplied is invalid or incorrect! Please confirm and try again."
      );
    }
    if (status === "server_down") {
      throw new Error(
        "The verification portal is down at the moment! Not to worry, we will try again as soon as possible!"
      );
    }
    throw new Error(
      "There is an issue with your compliance information! Please check your email for more information."
    );
  };
  const updateSettingsAccount = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      bank: values.bank.code,
      bankName: values.bank.name,
      update_account: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating account! Please try again later."
      );
    }
  };
  const getLatLng = async (address) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&sensor=false&key=AIzaSyCIoJOru4dY3Vx7vScp7X2M92eOYOuvOhw`;
    const response = await axios.get(url, { headers: null });
    return (
      (response.data.results &&
        response.data.results.length > 0 &&
        response.data.results[0].geometry.location) ||
      {}
    );
  };
  const getListingDetail = async (listingId) => {
    const response = await axios.post(API_PATH.listings, {
      listingId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const getBookingDetail = async (bookingId) => {
    const response = await axios.post(API_PATH.bookings, {
      bookingId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const getEventDetail = async (eventId) => {
    const response = await axios.post(API_PATH.events, {
      eventId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const getBoatDetail = async (boatId) => {
    const response = await axios.post(API_PATH.boats, {
      boatId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const saveListingHost = async (values) => {
    const response = await axios.post(API_PATH.listings, {
      saveHost: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data, isVerified: true } });
      return;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const saveListingProperty = async (values) => {
    const response = await axios.post(API_PATH.listings, {
      save: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid property and listing type selected! Please select a valid property and listing type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const addRoomType = async (values) => {
    const response = await axios.post(API_PATH.listings, {
      addRoom: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid listing selected! Please select a valid listing to proceed."
      );
    } else if (status === "invalid_listing") {
      throw new Error(
        "Invalid listing selected! Please select a valid listing to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const approveRejectReview = async (item, action) => {
    const response = await axios.post(API_PATH.reviews, {
      update: (item && item.reviewId) || "",
      action,
    });
    const { status } = response.data;
    if (status === "success") {
      return true;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const addStaff = async (values) => {
    const response = await axios.post(API_PATH.staff, {
      add: "",
      ...values,
      role: (values && values.roleId) || "",
      listings:
        (values && values.listings && JSON.stringify(values.listings)) || "[]",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "email_exists") {
      throw new Error(
        "The email address you provided is already in use! Please enter another to proceed."
      );
    } else if (status === "username_exists") {
      throw new Error(
        "The username you provided is already in use! Please enter another to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const updateStaff = async (values, staffId) => {
    const response = await axios.post(API_PATH.staff, {
      update: "",
      ...values,
      role: (values && values.roleId) || "",
      listings:
        (values && values.listings && JSON.stringify(values.listings)) || "[]",
      staffId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "email_exists") {
      throw new Error(
        "The email address you provided is already in use! Please enter another to proceed."
      );
    } else if (status === "username_exists") {
      throw new Error(
        "The username you provided is already in use! Please enter another to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const updateStaffStatus = async (newStatus, staffId) => {
    const response = await axios.post(API_PATH.staff, {
      update_status: staffId,
      newStatus,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const addRole = async (values) => {
    const response = await axios.post(API_PATH.role, {
      add: "",
      ...values,
      permissions: values.permissions
        ? JSON.stringify(values.permissions)
        : "[]",
      role: (values && values.roleId) || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "name_exists") {
      throw new Error(
        "The name you provided is already in use! Please enter another to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const updateRole = async (values, roleId) => {
    const response = await axios.post(API_PATH.role, {
      update: "",
      ...values,
      permissions: values.permissions
        ? JSON.stringify(values.permissions)
        : "[]",
      roleId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "name_exists") {
      throw new Error(
        "The name you provided is already in use! Please enter another to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const updateRoleStatus = async (newStatus, roleId) => {
    const response = await axios.post(API_PATH.role, {
      update_status: roleId,
      newStatus,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const addTicket = async (values) => {
    const response = await axios.post(API_PATH.events, {
      addTicket: "",
      ...values,
      event: (values.event && values.event.uid) || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid event selected! Please select a valid event to proceed."
      );
    } else if (status === "invalid_ticket") {
      throw new Error(
        "Invalid ticket selected! Please select a valid ticket to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const saveProperty = async (values) => {
    const response = await axios.post(API_PATH.properties, {
      save: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid property and listing type selected! Please select a valid property and listing type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const addBooking = async (values) => {
    const response = await axios.post(API_PATH.bookings, {
      add: "",
      ...values,
      extras: JSON.stringify(values.extras),
      roomType: JSON.stringify(values.roomType),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_guest") {
      throw new Error(
        "No guest name provided! Please enter a valid guest name to proceed."
      );
    } else if (status === "invalid_room") {
      throw new Error(
        "No room type selected! Please enter a valid room type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const updateBooking = async (values) => {
    const response = await axios.post(API_PATH.bookings, {
      update: "",
      ...values,
      extras: JSON.stringify(values.extras),
      roomType: JSON.stringify(values.roomType),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_guest") {
      throw new Error(
        "No guest name provided! Please enter a valid guest name to proceed."
      );
    } else if (status === "invalid_room") {
      throw new Error(
        "No room type selected! Please enter a valid room type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const saveListingAutomobile = async (values) => {
    const response = await axios.post(API_PATH.automobiles, {
      save: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid vehicle type selected! Please select a valid vehicle type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const saveListingBoat = async (values) => {
    const response = await axios.post(API_PATH.boats, {
      save: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid boat type selected! Please select a valid boat type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const saveListingEvent = async (values) => {
    const response = await axios.post(API_PATH.events, {
      save: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid listing type selected! Please select a valid listing type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const saveListingGuide = async (values) => {
    const response = await axios.post(API_PATH.listings, {
      saveGuide: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    } else if (status === "invalid_type") {
      throw new Error(
        "Invalid property and listing type selected! Please select a valid property and listing type to proceed."
      );
    }
    throw new Error(
      "Something went wrong while processing your request! Please try again later."
    );
  };
  const requestLink = async () => {
    const response = await axios.post(API_PATH.settings, { getLink: "" });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    } else {
      throw new Error(
        "Something went wrong while generating your referral link! Please try again later."
      );
    }
  };
  const uploadFile = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.misc, formData, config);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while attempting to upload file! Please try again later."
    );
  };
  const getPermissions = async () => {
    const response = await axios.post(API_PATH.misc, { permissions: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const confirmBankTransfer = async (amount, isProcess) => {
    const response = await axios.post(API_PATH.bookings, {
      confirmTransfer: "",
      amount,
      isProcess,
    });
    const { status, payments } = response.data;
    if (status === "success") {
      return payments;
    }
    throw new Error(
      "Something went wrong while checking for transfers! Please confirm you are connected to the internet. We'll keep checking..."
    );
  };
  const payBill = async (values) => {
    const response = await axios.post(API_PATH.billPayments, {
      pay: "",
      ...values,
      type: values.type.code || "",
      plan: values.plan.code || "",
    });
    const { status } = response.data;
    if (status === "success") {
      return true;
    }
    if (status === "insufficient_balance") {
      throw new Error(
        "You do not have sufficient balance to complete this payment. Please fund your wallet and  try again later."
      );
    }
    throw new Error(
      "Something went wrong while processing your transaction. Please try again later."
    );
  };
  const fetchBillingProviders = async (type) => {
    const response = await axios.post(API_PATH.billPayments, {
      providers: type,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching providers. Please try again later."
    );
  };
  const fetchBillingPlans = async (type, provider) => {
    const response = await axios.post(API_PATH.billPayments, {
      plans: type,
      provider,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching plans. Please try again later."
    );
  };
  const verifyBillRecipient = async (
    type,
    provider,
    recipient,
    recipientType
  ) => {
    const response = await axios.post(API_PATH.billPayments, {
      validate: type,
      provider,
      recipient,
      recipientType,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Your recipient does not exists or cannot be verified at the moment. Please try again later."
    );
  };
  const fetchBeneficiaries = async (type, provider) => {
    const response = await axios.post(API_PATH.billPayments, {
      beneficiaries: type,
      provider,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching beneficiaries. Please try again later."
    );
  };
  const getBalance = async () => {
    const response = await axios.post(API_PATH.misc, { balance: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getBillDefaults = async () => {
    const response = await axios.post(API_PATH.misc, { billDefaults: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return {};
  };
  return (
    <AuthContext.Provider
      // eslint-disable-next-line
      value={{
        ...state,
        method: "jwt",
        saveToken,
        login,
        logout,
        updateSettings,
        sendPasswordVerify,
        getBanks,
        getDevices,
        getAccountName,
        forgotPassword,
        resetPassword,
        updateSettingsAccount,
        saveListingHost,
        sendVerifyCode,
        verifyAccount,
        updateCompliance,
        initialize,
        requestLink,
        checkPhone,
        signup,
        logoutDevice,
        getPlans,
        getListings,
        uploadFile,
        getLatLng,
        saveListingProperty,
        saveListingGuide,
        getBookingDefaults,
        saveListingEvent,
        saveProperty,
        saveListingAutomobile,
        saveListingBoat,
        getListingDetail,
        getEventDetail,
        addBooking,
        addTicket,
        switchAccount,
        getBoatDetail,
        addRoomType,
        getBookingDetail,
        updateBooking,
        approveRejectReview,
        topupWallet,
        transferWallet,
        getRoles,
        addStaff,
        updateStaff,
        updateStaffStatus,
        getPermissions,
        addRole,
        updateRole,
        updateRoleStatus,
        confirmBankTransfer,
        getStaffs,
        getBalance,
        fetchBeneficiaries,
        getBillDefaults,
        payBill,
        fetchBillingPlans,
        verifyBillRecipient,
        fetchBillingProviders,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
