import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  Stack,
  Divider,
} from "@mui/material";
import useAuth from "../hooks/useAuth";
import { MHidden } from "./@material-extend";

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.subtitle2,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  fontSize: 16,
  color: "#403C3C",
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
  },
}));

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  color: "#403C3C",
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: "rgba(64, 60, 60, 0.7)",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object,
};

export function NavItem({ item, active, isShow }) {
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);
  const { logout } = useAuth();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: 400,
    "&:before": {
      top: 0,
      right: 0,
      width: 2,
      bottom: 0,
      content: "''",
      display: "block",
      position: "absolute",
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: { md: "rgba(65, 177, 26, 1)", xs: "transparent" },
    },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: { md: "text.disabled", xs: "#403C3C" },
                          transition: (theme) =>
                            theme.transitions.create("transform"),
                          ...(isActiveSub && {
                            transform: "scale(2)",
                            bgcolor: "primary.main",
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return path === "logout" ? (
    <ListItemStyle
      onClick={handleLogout}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText
            disableTypography
            primary={title}
            sx={{ fontSize: { md: "0.875rem", xs: 13 } }}
          />
          {info && info}
          <MHidden width="lgUp">
            <Icon
              icon="mingcute:right-line"
              style={{
                width: 15,
                height: 15,
                color: isActiveRoot ? "rgba(65, 177, 26, 1)" : "#403C3C",
              }}
            />
          </MHidden>
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => pathname === path;

  return (
    <Box {...other} sx={{ mx: { md: 0, xs: 2 } }}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List
            key={subheader}
            disablePadding
            sx={{
              bgcolor: { md: "transparent", xs: "common.white" },
              borderRadius: 2,
              py: { md: 0, xs: 1 },
              mb: { md: 0, xs: 2 },
            }}
          >
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            <Stack
              divider={
                <Divider
                  flexItem
                  sx={{
                    display: { md: "none", xs: "block" },
                    borderColor: "rgba(0, 0, 0, 0.1)",
                    mx: "16px!important",
                  }}
                />
              }
              spacing={{ md: 0, xs: 0.2 }}
            >
              {items.map((item) => (
                <NavItem
                  key={item.title}
                  item={item}
                  active={match}
                  isShow={isShow}
                />
              ))}
            </Stack>
          </List>
        );
      })}
    </Box>
  );
}
