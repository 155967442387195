// components
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import MotionPhotosAutoOutlinedIcon from "@mui/icons-material/MotionPhotosAutoOutlined";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

export const sidebarConfig = [
  {
    subheader: "Account Settings",
    items: [
      {
        title: "Personal Info",
        path: PATH_DASHBOARD.general.account,
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        title: "My Account",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: "My Wallet",
        path: PATH_DASHBOARD.general.root,
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
    ],
  },
  {
    subheader: "Listings",
    items: [
      {
        title: "My Bookings",
        path: PATH_DASHBOARD.bookings,
        icon: <MenuBookOutlinedIcon />,
      },
      {
        title: "Saved Wishlist",
        path: PATH_DASHBOARD.wishlist,
        icon: <FavoriteBorderOutlinedIcon />,
      },
      {
        title: "Messages",
        path: PATH_DASHBOARD.messages,
        icon: <ChatOutlinedIcon />,
      },
      {
        title: "Reviews",
        path: PATH_DASHBOARD.reviews,
        icon: <VisibilityOutlinedIcon />,
      },
    ],
  },
  {
    subheader: "Support",
    items: [
      {
        title: "Visit the Help center",
        path: PATH_DASHBOARD.general.notification,
        icon: <HelpOutlineOutlinedIcon />,
      },
      {
        title: "Report a neighbourhood concern",
        path: PATH_DASHBOARD.general.notification,
        icon: <SupportAgentOutlinedIcon />,
      },
      {
        title: "How Afritas Works",
        path: PATH_DASHBOARD.general.notification,
        icon: <MotionPhotosAutoOutlinedIcon />,
      },
    ],
  },
];
