// ----------------------------------------------------------------------
export function getFirstCharacter(name) {
  const names = name.split(" ");
  return String(
    names
      .map((n) => n.charAt(0))
      .filter((n) => n !== "")
      .join("")
  ).toLocaleUpperCase();
}
