import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Drawer,
  Tooltip,
  CardActionArea,
  Typography,
  Divider,
  Avatar,
} from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Icon } from "@iconify/react";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection, { NavItem } from "../../components/NavSection";
//
import { MHidden } from "../../components/@material-extend";
import { sidebarConfig } from "./SidebarConfig";
import useAuth from "../../hooks/useAuth";
import { getFirstCharacter } from "../../utils/createAvatar";
import { ASSETS_PATH } from "../../utils/apis";

const DRAWER_WIDTH = 300;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool,
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Collapse Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          cursor: "pointer",
          borderRadius: "50%",
          alignItems: "center",
          color: "text.primary",
          justifyContent: "center",
          border: "solid 1px currentColor",
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: "currentColor",
            transition: (theme) => theme.transitions.create("all"),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        bgcolor: { md: "#ffffff", xs: "#F4F4F4" },
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <Logo disabledLink sx={{ height: 60 }} />
          </Box>
        </Stack>
        <MHidden width="lgUp">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 1, bgcolor: "common.white", borderRadius: 1.5, py: 2 }}
            spacing={2}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                src={
                  (user &&
                    user.user &&
                    user.user.photo &&
                    `${ASSETS_PATH.customers}${user.user.photo}`) ||
                  ""
                }
                alt={(user && user.user && user.user.name) || ""}
              >
                {getFirstCharacter((user && user.user && user.user.name) || "")}
              </Avatar>
              <Stack spacing={0.1}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "#403C3C", fontSize: 13 }}
                >
                  {(user && user.user && user.user.name) || ""}
                </Typography>
                <Typography variant="caption" sx={{ color: "#403C3C" }}>
                  Show profile
                </Typography>
              </Stack>
            </Stack>
            <Icon
              icon="mingcute:right-line"
              style={{ width: 15, height: 15, color: "#403C3C" }}
            />
          </Stack>
        </MHidden>
        <MHidden width="lgUp">
          <Stack
            direction="column"
            sx={{ p: 1.5, bgcolor: "common.white", borderRadius: 1.5, py: 2 }}
            spacing={1}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#403C3C", fontSize: 16 }}
            >
              Create Listing
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: "#403C3C", fontSize: 13 }}
            >
              it's simple to get set up and start earning.
            </Typography>
          </Stack>
        </MHidden>
      </Stack>
      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && (
        <Stack
          spacing={1}
          alignItems="start"
          sx={{
            px: 3,
            pb: 5,
            mt: 10,
            width: 1,
            textAlign: "start",
            display: { md: "flex", xs: "none" },
          }}
          divider={
            <Divider flexItem sx={{ borderColor: "rgba(64, 60, 60, 0.2)" }} />
          }
        >
          <NavItem
            item={{
              title: "Logout",
              path: "logout",
              icon: <LogoutOutlinedIcon />,
            }}
            active={() => false}
            isShow
          />
          <Typography
            variant="body2"
            sx={{
              textAlign: "start",
              fontSize: 13,
              fontWeight: 400,
              color: "rgba(64, 60, 60, 0.7)",
            }}
          >
            Copyright &copy; {new Date().getFullYear()} Afritas.
            <br />
            All rights reserved
          </Typography>
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        displayPrint: "none",
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: { md: DRAWER_WIDTH, xs: 300 } },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88),
              }),
              border: "none",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
