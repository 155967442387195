import { Link as RouterLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, Outlet } from "react-router-dom";
// material
import { styled, useTheme } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { Box, Link, Stack, Typography } from "@mui/material";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { PATH_DASHBOARD } from "../../routes/paths";
import bottomMenuConfig from "./bottomMenuConfig";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 50;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  backgroundColor: "#f5faf4",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------
const useBasePath = () => {
  const location = useLocation();
  const params = useParams();
  if (Object.prototype.hasOwnProperty.call(params, "*")) {
    delete params["*"];
  }
  return location.pathname.split("/").length - 1 === 1
    ? location.pathname
    : Object.values(params).reduce(
        (path, param) => path.replace(`/${param}`, ""),
        location.pathname
      );
};
const EXCLUDENAV = [
  PATH_DASHBOARD.addListings,
  PATH_DASHBOARD.selectBusinessType,
  PATH_DASHBOARD.editListings,
];
export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const [bottom, setBottom] = useState(0);
  const pathname = useBasePath();
  const lastPosition = useRef(0);
  useEffect(() => {
    const handleScroll = () => {
      const newPosition = window.scrollY;
      setBottom(newPosition > lastPosition ? -60 : 0);
      lastPosition.current = newPosition;
    };
    if (isMobile) {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }
    return () => {
      if (isMobile) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <RootStyle>
      {!EXCLUDENAV.includes(pathname) && (
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      )}
      {!EXCLUDENAV.includes(pathname) && (
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      )}
      <MainStyle
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            ml: "102px",
          }),
          ...(EXCLUDENAV.includes(pathname) && {
            ml: "0px!important",
            pt: "0px!important",
            px: "0px!important",
            pb: "0px!important",
            backgroundColor: "#f5f5f5",
          }),
        }}
      >
        <Outlet />
        {isMobile && (
          <Box
            sx={{
              width: 1,
              maxWidth: 1,
              bgcolor: "#fff",
              position: "fixed",
              zIndex: 5,
              bottom: bottom,
              py: 1,
              borderTop: "1px solid rgba(16, 16, 16, 0.1)",
              transition: "bottom 0.5s ease",
            }}
          >
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              justifyContent="space-around"
              sx={{ width: 1, overflow: "hidden", height: 50 }}
            >
              {bottomMenuConfig.map(
                (menu, index) =>
                  (menu.path.startsWith("http") && (
                    <Link
                      key={index}
                      style={{ textDecoration: "none", color: "inherit" }}
                      href={menu.path}
                      variant="body2"
                    >
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          pb: { md: 0.5, xs: 0 },
                          borderColor:
                            pathname === menu.path
                              ? "primary.main"
                              : "rgba(64, 60, 60, 0.7)",
                          cursor: "pointer",
                          "&:hover": {
                            borderBottom: "2px solid",
                            borderColor:
                              pathname === menu.path
                                ? "primary.main"
                                : "rgba(64, 60, 60, 0.7)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            color:
                              pathname === menu.path
                                ? "primary.main"
                                : "rgba(64, 60, 60, 0.7)",
                          }}
                        >
                          {menu.icon}
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{
                            width: "max-content",
                            fontSize: { md: 14, xs: 12 },
                            color:
                              pathname === menu.path
                                ? "primary.main"
                                : "rgba(64, 60, 60, 0.7)",
                            fontWeight: 400,
                          }}
                        >
                          {menu.title}
                        </Typography>
                      </Stack>
                    </Link>
                  )) || (
                    <Link
                      key={index}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={menu.path}
                      variant="body2"
                      component={RouterLink}
                    >
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          pb: { md: 0.5, xs: 0 },
                          borderColor:
                            pathname === menu.path
                              ? "primary.main"
                              : "rgba(64, 60, 60, 0.7)",
                          cursor: "pointer",
                          "&:hover": {
                            borderBottom: "2px solid",
                            borderColor:
                              pathname === menu.path
                                ? "primary.main"
                                : "rgba(64, 60, 60, 0.7)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            color:
                              pathname === menu.path
                                ? "primary.main"
                                : "rgba(64, 60, 60, 0.7)",
                          }}
                        >
                          {menu.icon}
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{
                            width: "max-content",
                            fontSize: { md: 14, xs: 12 },
                            color:
                              pathname === menu.path
                                ? "primary.main"
                                : "rgba(64, 60, 60, 0.7)",
                            fontWeight: 400,
                          }}
                        >
                          {menu.title}
                        </Typography>
                      </Stack>
                    </Link>
                  )
              )}
            </Stack>
          </Box>
        )}
      </MainStyle>
    </RootStyle>
  );
}
