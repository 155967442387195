// ----------------------------------------------------------------------
import ExploreIcon from "@mui/icons-material/Explore";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import { PATH_DASHBOARD } from "src/routes/paths";

const bottomMenuConfig = [
  {
    title: "Explore",
    icon: <ExploreIcon />,
    path: "https://useafritas.com",
  },
  {
    title: "Wishlist",
    icon: <FavoriteBorderIcon />,
    path: PATH_DASHBOARD.wishlist,
  },
  {
    title: "My Account",
    icon: <AccountCircleOutlined />,
    path: PATH_DASHBOARD.general.settings,
  },
];

export default bottomMenuConfig;
