import axios from "./axios";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    setHeader();
  } else {
    localStorage.removeItem("accessToken");
  }
};
const setHeader = () => {
  const accessToken = window.localStorage.getItem("accessToken")
    ? window.localStorage.getItem("accessToken")
    : "";
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const setHideWallet = (isHidden) => {
  if (isHidden) {
    localStorage.setItem("hideWallet", "true");
  } else {
    localStorage.removeItem("hideWallet");
  }
};
const getHideWallet = () => window.localStorage.getItem("hideWallet") || "";

export { setSession, setHeader, setHideWallet, getHideWallet };
