// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  Login: path(ROOTS_AUTH, "login"),
  signup: "https://selpay.co/agent-signup",
  welcome: path(ROOTS_AUTH, "welcome"),
  forgotPassword: path(ROOTS_AUTH, "forgot-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  verify: path(ROOTS_AUTH, "verify"),
  setPassword: path(ROOTS_AUTH, "set-password"),
};

export const PATH_PAGE = {
  page404: "404",
  errorOccured: "500",
  home: "https://useafritas.com",
  privacy: "https://useafritas.com/privacy",
  terms: "https://useafritas.com/terms",
  paymentTerms: "https://useafritas.com/payment-terms",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    settings: path(ROOTS_DASHBOARD, "settings"),
    security: path(ROOTS_DASHBOARD, "settings/security"),
    notification: path(ROOTS_DASHBOARD, "settings/notification"),
    insights: path(ROOTS_DASHBOARD, "settings/insight"),
    privacy: path(ROOTS_DASHBOARD, "settings/privacy"),
    subscriptions: path(ROOTS_DASHBOARD, "settings/subscriptions"),
    account: path(ROOTS_DASHBOARD, "settings/profile"),
    payment: path(ROOTS_DASHBOARD, "settings/payment"),
  },
  bookings: path(ROOTS_DASHBOARD, "bookings"),
  reviews: path(ROOTS_DASHBOARD, "reviews"),
  messages: path(ROOTS_DASHBOARD, "messages"),
  wishlist: path(ROOTS_DASHBOARD, "wishlist"),
  billPayments: path(ROOTS_DASHBOARD, "bill-payments"),
  misc: {
    log: path(ROOTS_DASHBOARD, "log"),
    support: path(ROOTS_DASHBOARD, "support"),
    sendSupport: path(ROOTS_DASHBOARD, "support/send"),
    onboarding: path(ROOTS_DASHBOARD, "onboarding"),
  },
};

export const frontendPages = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/404",
];
